import React from 'react';
import { graphql } from 'gatsby';
import { css } from 'styled-components';

import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import { HeroSection, SectionMenu, SpeakingEngSection } from '../../components/PressPage';
import MailingListPopup from '~/components/JoinMailingList/MailingListPopup';

const SpeakingEngagements = ({ data, location }) => {
    const { title, contentModules } = data.contentfulPage;

    const heroSection = contentModules.find(m => m.__typename === 'ContentfulPressPageHeroSection');
    const speakingEngSection = contentModules.find(
        m => m.__typename === 'ContentfulPressPageSpeakingEngagementsSection'
    );

    return (
        <>
            <MailingListPopup />
            <SEO title={title} />
            <Criteo />
            <DeriveHeaderClasses location={location} />
            <div>
                <HeroSection section={heroSection} />
                <section
                    css={css`
                        padding: 60px 0;
                    `}
                >
                    <SectionMenu location={location} />
                    {speakingEngSection.title ? (
                        <SpeakingEngSection section={speakingEngSection} />
                    ) : (
                        ''
                    )}
                </section>
            </div>
        </>
    );
};

export const query = graphql`
    {
        bgImages: allFile(
            filter: {
                extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                absolutePath: { regex: "/images/background/" }
            }
        ) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid(maxWidth: 915, quality: 100) {
                            aspectRatio
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        contentfulPage(slug: { eq: "press" }) {
            title
            contentModules {
                ... on ContentfulPressPageHeroSection {
                    heading
                    description {
                        description
                    }
                    backgroundImage {
                        fluid(maxWidth: 1600) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                        fixed(width: 1600) {
                            src
                        }
                    }
                }
                ... on ContentfulPressPageInTheNewsSection {
                    title
                    news {
                        title
                        link
                        featuredImage {
                            description
                            fluid(maxWidth: 800) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                        }
                    }
                    logoSection {
                        title
                        heading
                        logoBlock {
                            url
                            title
                            image {
                                description
                                fluid(maxWidth: 120) {
                                    ...GatsbyContentfulFluid_withWebp
                                }
                                fixed(width: 70) {
                                    width
                                    height
                                    src
                                    srcSet
                                }
                            }
                        }
                    }
                }
                ... on ContentfulPressPageSpeakingEngagementsSection {
                    title
                }
                ... on ContentfulPressPagePressKitSection {
                    title
                    download {
                        file {
                            url
                        }
                    }
                    foundedIn
                    instagram
                    twitter
                    facebook
                    youtube
                    contactUs {
                        contactUs
                    }
                    ourHeadquarters {
                        ourHeadquarters
                    }
                    truckLocations {
                        truckLocations
                    }
                    restaurantLocations {
                        restaurantLocations
                    }
                    companyOverview {
                        companyOverview
                    }
                    founders {
                        title
                        description
                        fluid(maxWidth: 800) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                    foundersDescription {
                        foundersDescription
                    }
                    awards {
                        awards
                    }
                    appearedIn {
                        appearedIn
                    }
                    asSeenOn {
                        asSeenOn
                    }
                    appearedAt {
                        appearedAt
                    }
                    logoSection {
                        logoBlock {
                            image {
                                description
                                fluid(maxWidth: 120) {
                                    ...GatsbyContentfulFluid_withWebp
                                }
                                fixed(width: 70) {
                                    src
                                }
                            }
                            url
                        }
                    }
                    videos {
                        poster {
                            description
                            fluid(maxWidth: 800) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                        }
                        videoLink
                    }
                    photos {
                        description
                        file {
                            url
                        }
                        fluid(maxWidth: 600) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default SpeakingEngagements;
